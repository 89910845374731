<template>
  <div class="page page-xtrading-all-campaign">

    <Side
      component="forms/campaign/compact/FormCampaignCompact"
      :ids="item ? [item.id] : []"
      :value="showEdit"
      @close="closeEdit"
      @update="loadItem"
    />

    <PageHeader
    back="/xtrading/campaigns/"
    :icon="$t('CAMPAIGN.ICON')"
    :title="item ? item.title : $t('CAMPAIGN.TITLE')"
    >

      <template v-slot:nav>
        <div class="d-flex" v-if="item">
          <StatusLabel :item="item" small class="mr-4" />
          <strong>
            <small v-if="new Date(item.start_date) > new Date()">{{$t('CAMPAIGN.START_DATE')}} : {{$moment(item.start_date).fromNow()}}</small>
            <small v-else-if="new Date(item.end_date) > new Date()">{{$t('CAMPAIGN.END_DATE')}} : {{$moment(item.end_date).fromNow()}}</small>
            <small v-else>{{$t('CAMPAIGN.FINISHED')}} : {{$moment(item.end_date).fromNow()}}</small>
          </strong>
        </div>
      </template>
      <template v-slot:navmenu>
        <v-tabs
          :value="tabValue"
          background-color="transparent"
          height="40px"
          optional
          slider-color="accent"
          slider-size="2"
          v-if="item"
        >

          <v-tab
            small
            :to="{ path: `/xtrading/campaign/${item.id}` }"
          >
            <v-icon size="16" class="mr-2">
              {{ $t('HEADER.OVERVIEW_ICON') }}
            </v-icon>
            {{ $t('HEADER.OVERVIEW') }}
          </v-tab>

          <v-tab
            small
            :to="{ path: `/xtrading/campaign/${item.id}/publications` }"
          >
            <v-icon size="16" class="mr-2">
              {{ $t('PUBLICATION.ICONS') }}
            </v-icon>
            {{ $t('PUBLICATION.TITLES') }}
              <v-chip
                x-small
                color="primary white--text"
                class="ml-2"
              >
                <span class="number">{{ (+countPublication||0).toLocaleString() }}</span>
              </v-chip>
          </v-tab>


          <v-tab
            small
            :to="{ path: `/xtrading/campaign/${item.id}/jobs` }"
          >
            <v-icon size="18" class="mr-2">
              {{$t('JOB.ICON')}}
            </v-icon>
            {{ $t('JOB.TITLES') }}
            <v-chip
              x-small
              color="primary white--text"
              class="ml-2"
            >
              <span class="number">{{ (+countJob||0).toLocaleString() }}</span>
            </v-chip>
          </v-tab>

          <v-spacer />
          <v-tab
            small
            :to="{ path: `/xtrading/campaign/${item.id}/imports` }"
          >
            <v-icon size="16" class="mr-2">
              {{ $t('CAMPAIGN.IMPORT_ICON') }}
            </v-icon>
            {{ $t('CAMPAIGN.IMPORT') }}
          </v-tab>

          <v-tab
            small
            :to="{ path: `/xtrading/campaign/${item.id}/rules` }"
          >
            <v-icon size="16" class="mr-2">
              {{ $t('CAMPAIGN_RULE.ICON') }}
            </v-icon>
            {{ $t('CAMPAIGN_RULE.TITLES') }}
            <v-chip
              x-small
              color="primary white--text"
              class="ml-2"
            >
              <span class="number">{{ (+countRule||0).toLocaleString() }}</span>
            </v-chip>
          </v-tab>



        </v-tabs>
      </template>

      <!-- <v-menu
        offset-y
        nudge-bottom="5px"
        :close-on-click="true"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on: onTip, attrs: attrsTip }">
              <v-btn
                fab
                small
                depressed
                color="primary"
                v-bind="{...attrsMenu, ...attrsTip}"
                v-on="{...onMenu, ...onTip}"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.MORE_ACTIONS') }}
          </v-tooltip>
        </template>

        <v-list nav dense>
          <v-list-item link @click="editItem">
            <v-list-item-icon><v-icon size="20">mdi-pencil</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                <strong>{{ $t('COMMON.EDIT') }}</strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-btn
        small
        depressed
        color="success"
        class="page-xranking-all-campaign__button-upload ml-2"
        @click="editItem"
      >
        <v-icon>mdi-pencil</v-icon>
        {{ $t('COMMON.EDIT_COMPANY') }}
      </v-btn>

    </PageHeader>


    <PageMain>
      <router-view
        :select="selected"
        :update="key"
        :item="item"
        @update="updatePage"
        @childData="handleChildData"
        :countPublication="countPublication"
      />
    </PageMain>


  </div>
</template>


<script>

import PageHeader from '@/components/PageHeader'
import PageMain from '@/components/PageMain'
// import Dialog from '@/components/Dialog'
import StatusLabel from '@/components/StatusLabel'
import Side from '@/components/Side'
// import DialogRemove from '@/components/DialogRemove'


const ELEMENT_NAME = 'campaign'
const TABLE_NAME = 'campaigns'
const SERVICE_NAME = 'api_programmatic'

export default {
  name: 'PageXtradingAllCampaign',
  components: {
    PageHeader,
    PageMain,
    // Dialog,
    Side,
    // DialogRemove,
    StatusLabel
  },
  data: (_this) => ({
    loading: false,
    ids: [],
    item: undefined,
    selected: [],
    showEdit: false,
    showNew: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    tabValue: _this.$route.path,
    key: 0,
    queryJobList: null,
    queryFeedList: null,
    queryPublicationList: null,
    countPublication: 0,
    countJob: 0,
    countFeed: 0,
    countRule: 0,
  }),
  props: {
    update:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
    select:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
  },

  computed: {
    paramsId () {
      return this.$router.currentRoute.params.id
    },
  },

  watch: {
    key() {
      this.loadItem()
      this.count()
    },
  },

  mounted () {
    this.loadItem()
    this.count()
  },

  methods: {

    pauseItem () {
      console.warning('pauseItem TODO')
    },

    playItem () {
      console.warning('playItem TODO')
    },

    statusReportValue(name, item) {
      if (item.status_report && item.status_report[name]) {
        return Number(item.status_report[name]).toLocaleString()
      }
      return 0
    },

    // Request

    handleChildData(data) {
      data = this.$clone(data)
      let c = false
      if (typeof data.queryJobList !== 'undefined') this.queryJobList = { ...data.queryJobList }, c = true
      if (typeof data.queryFeedList !== 'undefined') this.queryFeedList = { ...data.queryFeedList }, c = true
      if (typeof data.queryPublicationList !== 'undefined') this.queryPublicationList = { ...data.queryPublicationList }, c = true
      if (c) this.count()
    },

    updatePage(type) {
      if (type === 'job') this.countJobItem()
      if (type === 'feed') this.countFeedItem()
      if (type === 'publication') this.countPublicationItem()
    },

    count () {
      this.countFeedItem()
      this.countPublicationItem()
      this.countJobItem()
    },

    countJobItem() {
      const _query = this.queryJobList || { where: { status: [1], campaign_jobs: {campaign_id: this.paramsId, required: true }}}
      _query.options = Object.assign(_query.options || {}, { mode: 'count' })
      this.$services.api_programmatic.job.search(_query).then((response) => {
        this.countJob = response.data || 0
      })
    },

    countFeedItem() {
      const _query = this.queryFeedList || { where: { campaign_id: this.paramsId, status: [1] } }
      _query.options = Object.assign(_query.options || {}, { mode: 'count' })
      this.$services.api_programmatic.campaign_feed.search(_query).then((response) => {
        this.countFeed = response.data || 0
      })
    },

    countPublicationItem() {
      const _query = this.queryPublicationList || { where: { campaign_id: this.paramsId, status: [1] } }
      _query.options = Object.assign(_query.options || {}, { mode: 'count' })
      this.$services.api_programmatic.publication.search(_query).then((response) => {
        this.countPublication = response.data || 0
      })
    },

    // load campaign item
    loadItem() {
      this.loading = true
      this.$services.api_programmatic.campaign
        .search({ where: { id: this.paramsId, publication_count: true, } })
        .then((response) => {
          if (response.data && response.data[0]) {
            this.item = response.data[0]
            this.countRule = this.item.rules.length || 0
          } else {
            this.$router.push('/xtrading/campaigns')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    // remove item
    confirmRemoveItem() {
      this.$services.api_programmatic.campaign
        .remove({ where: { id: this.paramsId } })
        .then((response) => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.$router.push('/xtrading/campaigns')
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    // show confirmation to remove
    removeItem() {
      this.showRemove = true
    },

    newItem () {
      this.showNew = true
    },

    // show edit
    editItem() {
      this.showEdit = true
    },

    // show edit
    closeEdit() {
      this.showEdit = false
    },

    importJobs() {
      const route = `/xtrading/campaign/${  this.item.id  }/jobs`
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route)
      }
      this.showImportJobs = true
    },

    importFeeds() {
      const route = `/xtrading/campaign/${  this.item.id  }/feeds`
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route)
      }
      this.showImportFeeds = true
    },

    triggerPageUpdate() {
      this.key++
    },
  }
}
</script>

<style lang="scss">
@import '@/styles/mixins/add-button-styles.scss';

$margin-top: 10px;
$border-radius: 10px;
$gap: 5px;

.page-xranking-all-campaign__button-upload {
  @include add-button-styles($margin-top, $border-radius, $gap);
}
</style>
